import * as React from 'react'
import Layout from '../components/Layout'
import HaveQuestions from '../components/StillHaveQuestions'
import ContactAnimation from '../components/ContactAnimation'
import GiftBoxAnimation from '../components/GiftBoxAnimation'
import TelephoneAnimation from '../components/TelephoneAnimation'
import Seo from '../components/Seo'

const CheckoutThankYou = () => {
  return (
    <Layout className={'container mx-auto'} breadcrumbs={false}>
      <Seo
        title="Thank You"
        noindex={true}
        description={
          'Thank you for your order! Your order has been placed and will be processed soon. Please be sure to check your email for order details and confirmation.'
        }
      />
      <section className="w-10/12 mx-auto max-w-[1366px] py-10 text-center text-lg">
        <h1 className="font-black text-3xl uppercase text-teal-600">Your order has been placed!</h1>
        <p className="text-teal-600 font-bold uppercase">Thank you for your order!</p>
        <h2 className="py-10 uppercase text-gray-500 text-2xl">What happens next?</h2>
        <ul className="grid grid-cols-1 lg:grid-cols-3 text-base">
          <li className="w-full mx-auto max-w-[400px] px-2">
            <div className="w-full aspect-square relative">
              <div className="bg-gray-200 aspect-square rounded-full w-10/12 top-0 left-0 translate-x-[8.33%] translate-y-[8.33%] absolute -z-10"></div>
              <ContactAnimation />
            </div>
            Your order will be processed soon! Please be sure to check your email for order details
            and confirmation.
          </li>
          <li className="w-full mx-auto max-w-[400px] px-2">
            <div className="w-full aspect-square relative">
              <div className="bg-gray-200 aspect-square rounded-full w-10/12 top-0 left-0 translate-x-[8.33%] translate-y-[8.33%] absolute -z-10"></div>
              <TelephoneAnimation />
            </div>
            One of our In-House Experts will contact you for additional details, shipping and
            delivery information.
          </li>
          <li className="w-full mx-auto max-w-[400px] px-2">
            <div className="w-full aspect-square relative p-4">
              <div className="bg-gray-200 aspect-square rounded-full w-10/12 top-0 left-0 translate-x-[8.33%] translate-y-[8.33%] absolute -z-10"></div>
              <GiftBoxAnimation />
            </div>
            All orders are Insured and shipped via FedEx Next Day Delivery. You will be notified by
            email when your order has shipped.
          </li>
        </ul>
        <hr className="mt-10" />
        <HaveQuestions />
      </section>
    </Layout>
  )
}

export default CheckoutThankYou
